<template>
	<div id="personal-info">
		<hr class="thicker-radius" />
		<div v-if="serverBusy" class="loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<legend>{{ languageStrings.personalInfo }}</legend>
		<!-- <pre>{{ JSON.stringify(personalInfo, null, "\t") }}</pre> -->
		<label for="name-first">{{ languageStrings.firstName }}</label>
		<input id="name-first" type="text" :placeholder="languageStrings.firstName" v-model="nameFirst" />
		<label for="name-last">{{ languageStrings.lastName }}</label>
		<input id="name-last" type="text" :placeholder="languageStrings.lastName" v-model="nameLast" />
		<label for="birth-date">{{ languageStrings.birthDate }}</label>
		<input id="birth-date" type="date" :placeholder="languageStrings.birthDate" v-model="birthDate" />
		<label for="nationality">{{ languageStrings.nationality }}</label>
		<input id="nationality" type="text" :placeholder="languageStrings.nationality" v-model="nationality" />
		<label for="address-country">{{ languageStrings.addressCountry }}</label>
		<input
			id="address-country"
			type="text"
			:placeholder="languageStrings.addressCountry"
			v-model="addressCountry"
		/>
		<label for="address-county">{{ languageStrings.addressCounty }}</label>
		<input id="address-county" type="text" :placeholder="languageStrings.addressCounty" v-model="addressCounty" />
		<label for="address-zipcode">{{ languageStrings.zipcode }}</label>
		<input id="address-zipcode" type="text" :placeholder="languageStrings.zipcode" v-model="addressZipCode" />
		<button class="btn" @click="setPersonalInfo()">Save Personal Info</button>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "PersonalInfoBasicTransactions",
	props: {
		playerState: Object,
		userProfile: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			personalInfo: this?.userProfile?.personalInfo,
			nameFirst: "",
			nameLast: "",
			nameLast2: "",
			birthDate: new Date().toISOString().split("T")[0],
			nationality: "",
			emailAddress: "",
			emailVerificationStatus: "",
			addressCountry: "",
			addressCounty: "",
			addressZipCode: "",
		};
	},
	watch: {},
	created() {
		this.populateFields();
	},
	mounted() {},
	methods: {
		async setPersonalInfo() {
			this.serverBusy = true;
			this.busyText = "Updating Personal Info";

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				userId: this.playerState.userId,
				userProfile: {
					personalInfo: {
						birthDate: this.birthDate,
						nationality: this.nationality,
						nameFirst: this.nameFirst,
						nameLast: this.nameLast,
						addressCountry: this.addressCountry,
						addressCounty: this.addressCounty,
						addressZipCode: this.addressZipCode,
					},
				},
				requiredValidations: "Minimal, BasicTransactions",
			};

			let requestUrl = new URL("/api/v1/user/profile", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				});

				console.log(this.languageErrorStrings);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (dataJson?.status === "FailedPersonalInfoValidation") {
					dataJson.validationErrors.forEach((err) => {
						this.status.ok = false;
						this.status.message = `${this.camelToTitleCase(err.field)}: ${this.camelToTitleCase(
							err.reason
						)}`;
						this.eventBus.emit("updateStatus", this.status);
					});
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				if (dataJson?.status !== "Success") {
					this.status.ok = false;
					this.status.message = dataJson.status;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				let newPlayerState = this.playerState;

				newPlayerState.userProfile = dataJson.userProfile;
				this.personalInfo = dataJson?.userProfile?.personalInfo;

				this.status.ok = true;
				this.status.message = "Personal Info Updated";
				this.eventBus.emit("updatePlayerState", newPlayerState);
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("updateUserProfile");
				this.populateFields();
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
		populateFields() {
			this.nameFirst = this?.personalInfo?.nameFirst;
			this.nameLast = this?.personalInfo?.nameLast;
			// this.nameLast2 = this?.personalInfo?.nameLast2;
			this.birthDate = new Date(this?.personalInfo?.birthDate).toISOString().split("T")[0];
			this.nationality = this?.personalInfo?.nationality;
			this.emailAddress = this?.personalInfo?.emailAddress;
			// this.emailVerificationStatus = this?.personalInfo?.emailVerificationStatus;
			this.addressCountry = this?.personalInfo?.addressCountry;
			this.addressCounty = this?.personalInfo?.addressCounty;
			this.addressZipCode = this?.personalInfo?.addressZipCode;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading {
	display: grid;
	align-content: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	text-align: center;
	font-weight: bold;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	z-index: 100;
}

.loading-message {
	text-align: center;
	display: block;
	margin: 15px;
}

#personal-info {
	display: flex;
	flex-flow: column;
}

.thicker-radius {
	width: 100%;
}
</style>
